import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const API_URL = process.env.REACT_APP_API_URL; // Directly use the API URL from environment variables

const LoginPage = () => {
  const [mode, setMode] = useState('login'); // 'login' or 'register'
  const { login } = useAuth();  // Removed API_URL from here since it's no longer part of AuthContext
  const [userData, setUserData] = useState({
    email: '',
    username: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const url = mode === 'login' ? `${API_URL}/auth/login/` : `${API_URL}/auth/register/`;
      const response = await axios.post(url, userData);
      if (response.data.access) {  // Use 'access' for access token as typical with DRF simplejwt
        login(response.data.access, response.data.user, response.data.refresh);  // Assuming 'user' info and refresh token are also sent back
        navigate('/');  // Navigate to home on success
      } else {
        throw new Error("Access token not provided");
      }
    } catch (error) {
      console.error(`${mode === 'login' ? 'Login' : 'Registration'} Failed`, error.response ? error.response.data : error);
      const firstError = error.response?.data ? Object.values(error.response.data).flat()[0] : 'Unknown error occurred.';
      setError(firstError);
    } finally {
      setLoading(false);
    }
  };

  const toggleMode = () => {
    setMode(prev => prev === 'login' ? 'register' : 'login');
    setError(''); // Clear any existing errors
  };

  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          {mode === 'login' ? 'Login' : 'Register'}
        </Typography>
        <Button
          startIcon={<GoogleIcon />}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => console.log(`${mode === 'login' ? 'Login' : 'Sign up'} with Google`)}
        >
          {mode === 'login' ? 'Sign in with Google' : 'Sign up with Google'}
        </Button>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 2 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={userData.email}
            onChange={handleChange}
            disabled={loading}
          />
          {mode === 'register' && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="username"
              label="Username"
              type="text"
              id="username"
              autoComplete="username"
              value={userData.username}
              onChange={handleChange}
              disabled={loading}
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={userData.password}
            onChange={handleChange}
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {mode === 'login' ? 'Login' : 'Register'}
          </Button>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </form>
        <Button
          fullWidth
          sx={{
            mt: 2,
            color: '#c5cae9', // Light blue-grey text color for visibility and subtlety
            typography: 'body2', // Smaller text size
            p: 0.5, // Padding around the text for better touch interaction
            textTransform: 'none', // Removes uppercase transformation to reduce aggression
            border: '1px solid transparent', // Subtle border that becomes visible on hover
            '&:hover': {
              borderColor: '#7986cb', // Blue-grey border on hover for a subtle effect
              backgroundColor: 'rgba(255, 255, 255, 0.08)', // Slight background color change on hover
              color: '#fff' // White text on hover for better visibility
            }
          }}
          onClick={toggleMode}
        >
          {mode === 'login' ? 'Need an account? Register' : 'Already have an account? Login'}
        </Button>
      </div>
    </Container>
  );
};

export default LoginPage;
