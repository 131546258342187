import axios from 'axios';

// Directly use the environment variables set via .env files
const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserDetails = async (token) => {
    return axios.get(`${API_URL}/auth/users/me`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const refreshAuthToken = (refreshToken) => {
    return axios.post(`${API_URL}/auth/token/refresh/`, {
        refresh: refreshToken
    });
};
