import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import AppBar from './AppBar';

function BitbotsPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const handleDatasetClick = () => {
        navigate('/bitbots/datasets');
    };

    return (
        <Container component="main" maxWidth={false} sx={{ flexGrow: 1, height: "100vh", padding: 0, margin: 0, maxWidth: '100%' }}>
            <Grid container sx={{ height: '100%' }}>
                {!isMobile && (
                    <Grid item xs={2} sx={{ padding: 0, margin: 0 }}>
                        <Typography variant="button" onClick={handleDatasetClick} sx={{ cursor: 'pointer' }}>
                            Datasets
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={isMobile ? 12 : 8} sx={{ padding: 0 }}>
                    {/* Route-specific content goes here */}
                </Grid>
                {!isMobile && (
                    <Grid item xs={2} sx={{ padding: 0, margin: 0 }}>
                        {/* Right Sidebar Placeholder */}
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default BitbotsPage;