import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    color: theme.palette.text.primary,

    '& #sentimentGraph .highcharts-background': {
        fill: 'transparent',
    },
    '& #sentimentGraph .highcharts-container': {
        backgroundColor: 'transparent',
    },
    '& .MuiSelect-select, & .MuiToggleButton-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
        fontSize: '0.8rem !important',
        padding: '4px 8px !important',
    },
    '& .MuiSelect-select:hover, & .MuiToggleButton-root:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
    },
    '& .MuiToggleButton-root.Mui-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
    },
    '& .MuiInputLabel-root': {
        fontSize: '0.8rem !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        fontSize: '0.8rem !important',
    },
    '@media (max-width: 600px)': {
        '& .MuiToggleButton-root': {
            minWidth: '40px',
        },
        '& .MuiSelect-select': {
            paddingRight: '24px !important',
        },
    },
}));
