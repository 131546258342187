import React, { useEffect } from 'react';
import { ThemeProvider, CssBaseline, Container, Grid, Typography, Link, useMediaQuery, useTheme, Box } from '@mui/material';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { AuthProvider } from './AuthContext';
import AppBar from './components/AppBar';
import MainRoutes from './routes/MainRoutes';
import darkTheme from './themes/darkTheme';

// Initialize GA4 only in production
if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    console.log("Google Analytics initialized in production mode");
} else {
    console.log("Google Analytics not initialized in development mode");
}


function App() {
    const currentTheme = useTheme();
    const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={darkTheme}>
            <Router>
                <AuthProvider>
                    <CssBaseline />
                    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                        <AppBar />
                        <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <Container maxWidth={false} sx={{ flexGrow: 1, display: 'flex', padding: 0, margin: 0, width: '100%' }}>
                                <Grid container sx={{ flexGrow: 1 }}>
                                    {!isMobile && (
                                        <Grid item xs={2} sx={{ padding: 0, margin: 0 }}> {/* Left Navbar */}
                                            {/* Placeholder for Left Navbar content */}
                                        </Grid>
                                    )}
                                    <Grid item xs={isMobile ? 12 : 8} sx={{ padding: 0 }}> {/* Main Content */}
                                        <MainRoutes />
                                        <PageViewTracker />
                                    </Grid>
                                    {!isMobile && (
                                        <Grid item xs={2} sx={{ padding: 0, margin: 0 }}> {/* Right Sidebar */}
                                            {/* Placeholder for Right Sidebar content */}
                                        </Grid>
                                    )}
                                </Grid>
                            </Container>
                        </Box>
                        <Box component="footer" sx={{ bgcolor: 'background.footer', color: "white", py: 1 }}>
                            <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                <Link href="/contact" color="inherit" sx={{ px: 1 }}>Contact</Link>
                                <Link href="/about" color="inherit" sx={{ px: 1 }}>About</Link>
                            </Typography>
                        </Box>
                    </Box>
                </AuthProvider>
            </Router>
        </ThemeProvider>
    );
}

// Component to track page views
function PageViewTracker() {
    const location = useLocation();

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        }
    }, [location]);

    return null;
}

export default App;