import { useState, useEffect } from 'react';

const useSentimentData = (apiUrl, currentFrame, selectedSymbol, selectedSubreddit, movingAverageDays) => {
    const [symbols, setSymbols] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchSymbols() {
            try {
                const symbolsRes = await fetch(`${apiUrl}/api/reddit_sentiment/symbols/`);
                const symbolsData = await symbolsRes.json();
                setSymbols(symbolsData);
            } catch (error) {
                console.error('Error fetching symbols:', error);
                setError(error);
            }
        }

        fetchSymbols();
    }, [apiUrl]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const fullApiUrl = `${apiUrl}/api/reddit_sentiment/?timeframe=${currentFrame}&symbol=${selectedSymbol}&subreddit=${selectedSubreddit}`;
            try {
                const response = await fetch(fullApiUrl);
                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setError(error);
                setLoading(false);
            }
        }

        fetchData();
    }, [apiUrl, currentFrame, selectedSymbol, selectedSubreddit, movingAverageDays]);

    return { symbols, data, loading, error };
};

export default useSentimentData;