import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const API_URL = process.env.REACT_APP_API_URL; // Directly use the API URL from environment variables

const ProfilePage = () => {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
  });
  const [initialUserData, setInitialUserData] = useState({
    username: '',
    email: '',
  });
  const [error, setError] = useState('');
  const { token, user, login, refreshToken } = useAuth(); // Remove API_URL from here
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/auth/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const fetchedData = {
          username: response.data.username,
          email: response.data.email
        };
        setUserData(fetchedData);
        setInitialUserData(fetchedData);  // Save the initial data for comparison later
      } catch (error) {
        setError('Failed to fetch user details');
        console.error('Error fetching user data:', error);
      }
    };
    fetchData();
  }, [token, API_URL]);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
    setError('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(`${API_URL}/auth/users/me`, userData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      login(token, response.data, refreshToken); // Update context with new user details
      navigate('/'); // Navigate to home or a confirmation page
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error);
      // Parse and display specific error messages from backend
      const errorMessage = error.response?.data?.username ||
                           error.response?.data?.email ||
                           'Failed to update profile';
      setError(errorMessage);
    }
  };

  // Check if there are changes to enable the Save button
  const hasChanges = userData.email !== initialUserData.email || userData.username !== initialUserData.username;

  return (
    <Container component="main" maxWidth="sm">
      <Typography component="h1" variant="h5">Edit Profile</Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={userData.email}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          value={userData.username}
          onChange={handleChange}
        />
        {hasChanges && (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                >
                Save Changes
            </Button>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </form>
    </Container>
  );
};

export default ProfilePage;
