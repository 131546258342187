import React from 'react';
import { Typography, Box, Container, Avatar, Grid, IconButton, Divider } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';

function About() {
  const indentedParagraph = {
    textIndent: '2em', // This adds a tab-like indent to the start of the paragraph
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          About SoozHub
        </Typography>
        <Divider sx={{ my: 2, borderColor: 'primary.main', width: '50%', margin: 'auto' }} />
        <Box sx={{ my: 4, px: 3, py: 3, backgroundColor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
          <Typography variant="body1" paragraph sx={indentedParagraph}>
            SoozHub is a cutting-edge Financial Technology platform specializing in data curation, 
            sentiment analysis, and algorithmic trading research for cryptocurrency markets.
          </Typography>
          <Typography variant="body1" paragraph sx={indentedParagraph}>
            Our mission is to empower investors by providing comprehensive, real-time data analysis 
            and insights into cryptocurrency and NFT markets.
          </Typography>
          <Typography variant="body1" paragraph sx={indentedParagraph}>
            Currently, we offer Reddit sentiment analysis and NFT price tracking. We're actively 
            working on a public algorithmic trading platform leveraging our time series databases, 
            which will revolutionize how our users interact with and benefit from market data.
          </Typography>
        </Box>
        <Divider sx={{ my: 2, borderColor: 'primary.main', width: '50%', margin: 'auto' }} />
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }} align="center">
          Meet the Founder
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Avatar
              alt="Carlos Mastrangelo"
              src="/path-to-default-image.jpg"
              sx={{ width: 150, height: 150, margin: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>Carlos Mastrangelo</Typography>
            <Typography variant="body1" paragraph sx={indentedParagraph}>
              I'm a software developer specializing in deep neural networks, quant, and DeFi applications. 
              I've been developing the infrastructure of SoozHub for many years and am excited to finally 
              make it accessible to users.
            </Typography>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <IconButton aria-label="X" color="primary" href="https://twitter.com/itsaiwa">
                <XIcon />
              </IconButton>
              <IconButton aria-label="GitHub" color="primary" href="https://github.com/cmastrangelo">
                <GitHubIcon />
              </IconButton>
              <IconButton aria-label="Email" color="primary" href="mailto:carlos.mastrangelo@gmail.com">
                <EmailIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default About;