import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProfilePage from '../components/ProfilePage';
import LoginPage from '../components/LoginPage';
import AboutPage from '../components/About';
import ContactPage from '../components/Contact';
import RedditSentiment from '../components/graphs/RedditSentiment/RedditSentiment';
import BitbotsPage from '../components/BitbotsPage';

const API_URL = process.env.REACT_APP_API_URL;

const MainRoutes = () => {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<LoginPage apiUrl={API_URL} />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />

            {/* Mixed Access Route */}
            <Route path="/" element={<PrivateRoute publicAccess={true}><RedditSentiment apiUrl={API_URL} /></PrivateRoute>} />

            {/* Private Routes */}
            <Route path="/profile" element={<PrivateRoute><ProfilePage apiUrl={API_URL} /></PrivateRoute>} />

            {/* Bitbots Route - also private */}
            <Route path="/bitbots/*" element={<PrivateRoute><BitbotsPage apiUrl={API_URL} /></PrivateRoute>} />
        </Routes>
    );
};

export default MainRoutes;