import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { fetchUserDetails, refreshAuthToken } from './authAPI';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const initialAuth = () => {
        try {
            const savedToken = localStorage.getItem('authToken');
            const savedUser = localStorage.getItem('user');
            const savedRefreshToken = localStorage.getItem('refreshToken');
            return {
                token: savedToken,
                user: savedUser ? JSON.parse(savedUser) : null,
                refreshToken: savedRefreshToken
            };
        } catch (error) {
            console.error('Failed to retrieve auth data:', error);
            localStorage.clear();  // Clear all local storage
            return { token: null, user: null, refreshToken: null };
        }
    };

    const [auth, setAuth] = useState(initialAuth);

    const getUserDetails = useCallback(async (token) => {
        try {
            const response = await fetchUserDetails(token);
            setAuth(prev => ({ ...prev, user: response.data }));
            localStorage.setItem('user', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error fetching user data:', error);
            logout();  // Log out on error/failure
        }
    }, []);

    useEffect(() => {
        if (auth.token) {
            getUserDetails(auth.token);
        }
    }, [auth.token, getUserDetails]);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshToken();
        }, 1000 * 60 * 55); // Refresh 5 minutes before expiration

        return () => clearInterval(interval);
    }, [auth.refreshToken]);

    const refreshToken = async () => {
        if (!auth.refreshToken) return;
        try {
            const response = await refreshAuthToken(auth.refreshToken);
            const access = response.data.access;
            setAuth(prev => ({ ...prev, token: access }));
            localStorage.setItem('authToken', access);
        } catch (error) {
            console.error('Error refreshing token:', error);
            logout();  // Log out on failure
        }
    };

    const login = (token, user, refreshToken) => {
        setAuth({ token, user, refreshToken });
        localStorage.setItem('authToken', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('refreshToken', refreshToken);
    };

    const logout = () => {
        setAuth({ token: null, user: null, refreshToken: null });
        localStorage.clear();  // Clear all local storage for security
    };

    const isAuthenticated = () => {
        return !!auth.token;
    };

    return (
        <AuthContext.Provider value={{ ...auth, login, logout, isAuthenticated, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
