import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000817',
      paper: '#000817',
      appBar: '#000000',
      footer: '#000000'
    },
    text: {
      primary: '#ffffff',
      secondary: '#cccccc'
    },
    action: {
      active: '#ffffff',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          color: '#ffffff',
          borderBottom: '1px solid #6e6e6e',
          '& .MuiToolbar-root': {
            backgroundColor: '#000000',
          },
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#6e6e6e',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#6e6e6e',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#6e6e6e',
            },
            '&:hover fieldset': {
              borderColor: '#6e6e6e',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#6e6e6e',
            },
            '& input': {
              color: '#ffffff',
            }
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000817',
          color: '#ffffff',
          border: '1px solid #6e6e6e',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#115293',
            color: '#ffffff'
          },
          '&.Mui-selected': {
            backgroundColor: '#294c73',
            color: '#ffffff',
          }
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#1976d2',
          '&:hover': {
            backgroundColor: '#115293',
          },
        },
      },
    },
  },
});

export default darkTheme;